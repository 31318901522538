import { Injectable } from '@angular/core';
import { IErrorService } from '@core-services/error';
import { BehaviorSubject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import {
  AppError,
  AppErrorItem,
  ExtendedGraphQLFormattedError,
} from '@core-interfaces/global';
import { GraphQLFormattedError } from 'graphql';

@Injectable({
  providedIn: 'root',
})
export class ErrorService implements IErrorService {
  errorMessage: BehaviorSubject<string | null> = new BehaviorSubject<
    string | null
  >(null);
  public handleGraphQLErrorsForForm(errors: readonly GraphQLFormattedError[] | undefined, form?: FormGroup): void {
    const grapQLErrors = errors as ExtendedGraphQLFormattedError[];
    console.log("GraphQLErrors: ", grapQLErrors);
    if (
      !grapQLErrors ||
      grapQLErrors.length === 0
    ) {
      return;
    }
    const { errors: fieldErrors, isGeneralError, message } = grapQLErrors[0];
    if (isGeneralError) {
      this.errorMessage.next(message);
      return;
    }
    if (form) {
      console.log("Setting form errors");
      this.setFormErrors(form, fieldErrors);
    } else {
      this.errorMessage.next("¡Ha ocurrido un error inesperado!");
    }
  }

  public handleHttpErrorsForForm(
    error: AppError | undefined,
    form: FormGroup
  ): void {
    console.log("Error: ", error);
    if (!error) {
      return;
    }


    const { errors, isGeneralError, message } = error;
    if (isGeneralError) {
      this.errorMessage.next(message);
      return;
    }
    if (!errors || errors.length === 0) {
      return;
    }
    this.setFormErrors(form, errors);
  }

  public setGeneralError(message: string): void {
    this.errorMessage.next(message);
  }

  private setFormErrors(form: FormGroup, errors: AppErrorItem[]): void {
    errors.forEach((error) => {
      if (error.key && form.controls[error.key]) {
        form.controls[error.key].setErrors({ serverError: error.message });
      } else {
        console.warn(`Form control for key "${error.key}" not found.`);
      }
    });
  }
}
