import { Injectable } from '@angular/core';

import {
  ESettingItem,
  ICommonExpenseConfigFormInput,
  ICondoFormInput,
  ICondoService,
  IInterestPenaltyFormInput,
  IRemunerationConfigFormInput,
} from '@core-services/condo';
import { Apollo } from 'apollo-angular';

import { firstValueFrom, map, Observable } from 'rxjs';
import {
  type IQueryGetCondos,
  type IMutationToggleAlphanumeriKeyboardUsageResponse,
  type IMutationToggleCommonAreaReservationTimeRange,
  type IMutationToggleCommonExpensesVisibilityResponseData,
  type IQueryGetCondoByID,
  type IMutationCondosRegister,
  type IMutationUpdateCondos,
  mutation_toggle_alphanumeri_keyboard_usage,
  mutation_toggle_common_area_reservation_time_range_support,
  TOGGLE_COMMON_EXPENSES_VISIBILITY,
  QUERY_GET_ALL_CONDOS,
  query_get_condo_by_id,
  mutation_register_condos,
  mutation_update_condos,
  query_get_condo_settings,
  IGetCondoSettings,
  IMutationUpdateCondoSettings,
  mutation_update_condo_settings,
} from '@infra-adapters/graphql/Condos';
import {
  type ErrorMessage,
  type NumberOrNull,
  type StringOrNull,
  StorageKeys,
  BooleanOrNull,
} from '@core-interfaces/global';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import type { ICondoInput, ICondoUpdateInput } from '@core-ports/inputs/Condo';
import { ICondo } from '@core-ports/outputs/condo';
import { ICondoSettings } from '@core-ports/outputs/CondoSettings';
import { ICondoSettingsInput } from '@core-ports/inputs/CondoSettings';
import { ErrorService } from './Error.service';
@Injectable({
  providedIn: 'root',
})
export class CondoManager implements ICondoService {
  errorMessages: ErrorMessage = {
    name: {
      required: 'Nombre es requerido.',
      minlength: 'Nombre debe contener al menos 4 caracteres',
      serverError: 'Nombre incorrecto',
    },
    pairing_code: {
      min: 'Código de pareo debe ser mayor a 0',
      minlength: 'Código de pareo debe contener al menos 4 caracteres',
      serverError: 'Código de pareo incorrecto',
    },
    maximum_parking_time: {
      required: 'Minutos de estacionamiento es requerido.',
      min: 'Código de pareo debe ser mayor a 0',
      serverError: 'Minutos de estacionamiento incorrecto.',
    },
    inactivity_time: {
      required: 'Tiempo de inactividad es requerido.',
      min: 'Código de pareo debe ser mayor a 0',
      serverError: 'Minutos de inactividad incorrecto.',
    },
    devices_number: {
      required: 'Cantidad de dispositivos es requerido',
      min: 'Código de pareo debe ser mayor a 0',
      serverError: 'Cantidad de dispositivos incorrecto.',
    },
  };

  commonExpenseErrorMessages: ErrorMessage = {
    type: {
      required: 'El tipo de gasto común es requerido',
    },
    consumption_calculation: {
      required: 'El cálculo de consumo es requerido',
    },
    show_zero_cols: {
      required: 'Mostrar columnas en cero es requerido',
    },
    expiration_day: {
      required: 'El día de vencimiento es requerido',
    },
    gen_pdf: {
      required: 'Generar PDF es requerido',
    },
    show_totals: {
      required: 'Mostrar totales es requerido',
    },
    virtual_assistant: {
      required: 'Asistente virtual es requerido',
    },
    prevent_close_aliquot: {
      required: 'Prevenir cierre de cuota es requerido',
      serverError: 'Error al obtener prevenir cierre de cuota',
    },
  };

  interestErrorMessages: ErrorMessage = {
    rate: {
      required: 'Tasa es requerida.',
      min: 'Tasa debe ser mayor o igual a 0',
      max: 'Tasa debe ser menor o igual a 100',
    },
    rate_type: {
      required: 'Tipo de tasa es requerido.',
    },
    interest_type: {
      required: 'Tipo de interés es requerido.',
    },
    penalty_type: {
      required: 'Tipo de multa es requerido.',
    },
    penalty_amount: {
      required: 'Monto de multa es requerido.',
      min: 'Porcentaje debe ser mayor o igual a 0',
      max: 'Porcentaje debe ser menor o igual a 100',
    },
    penalty_currency_type: {
      required: 'Tipo de moneda de multa es requerido.',
    },
    penalty_min_debt: {
      required: 'Monto mínimo de deuda es requerido.',
      min: 'Monto mínimo de deuda debe ser mayor o igual a 0',
    },
    penalty_fund_id: {
      required: 'Fondo de multa es requerido.',
    },
  };

  remunerationErrorMessages: ErrorMessage = {
    mutual_insurance: {
      required: 'Seguro mutuo es requerido.',
    },
    mutual_rate: {
      required: 'Tasa mutua es requerida.',
    },
    isl_rate: {
      required: 'Tasa ISL es requerida.',
    },
    compensation_fund: {
      required: 'Fondo de compensación es requerido.',
    },
    create_expense_on_settlement: {
      required: 'Crear gasto en liquidación es requerido.',
    },
    include_expenses_in_reconciliation: {
      required: 'Incluir gastos en conciliación es requerido.',
    },
    show_canceled_settlements: {
      required: 'Mostrar liquidaciones canceladas es requerido.',
    },
    create_expense_on_advance: {
      required: 'Crear gasto en avance es requerido.',
    },
    union_organization_rut: {
      required: 'RUT de organización sindical es requerido.',
      pattern: 'RUT de organización sindical incorrecto',
    },
    bulk_settlements_enabled: {
      required: 'Liquidaciones masivas habilitadas es requerido.',
    },
  };

  condoForm: FormGroup<ICondoFormInput>;
  commonExpenseConfigForm: FormGroup<ICommonExpenseConfigFormInput>;
  interestConfigForm: FormGroup<IInterestPenaltyFormInput>;
  remunetarionConfigForm: FormGroup<IRemunerationConfigFormInput>;

  constructor(private apollo: Apollo, private errorService: ErrorService) {
    this.condoForm = new FormGroup<ICondoFormInput>({
      name: new FormControl<StringOrNull>(null, {
        validators: [Validators.required, Validators.minLength(4)],
      }),
      devices_number: new FormControl<NumberOrNull>(null, {
        validators: [Validators.required, Validators.min(1)],
      }),
      inactivity_time: new FormControl<NumberOrNull>(null, {
        validators: [Validators.required, Validators.min(1)],
      }),
      maximum_parking_time: new FormControl<NumberOrNull>(null, {
        validators: [Validators.required, Validators.min(1)],
      }),
      pairing_code: new FormControl<NumberOrNull>(null, {
        validators: [Validators.min(1), Validators.minLength(4)],
      }),
      primary_phone_number: new FormControl<StringOrNull>(null),
      secondary_phone_number: new FormControl<StringOrNull>(null),
      address: new FormControl<StringOrNull>(null),
    });
    this.commonExpenseConfigForm = new FormGroup<ICommonExpenseConfigFormInput>(
      {
        type: new FormControl<StringOrNull>(null, [Validators.required]),
        consumption_calculation: new FormControl<StringOrNull>(null, [
          Validators.required,
        ]),
        show_zero_cols: new FormControl<BooleanOrNull>(null, [
          Validators.required,
        ]),
        expiration_day: new FormControl<StringOrNull>(null, [
          Validators.required,
        ]),
        gen_pdf: new FormControl<BooleanOrNull>(null, [Validators.required]),
        show_totals: new FormControl<BooleanOrNull>(null, [
          Validators.required,
        ]),
        virtual_assistant: new FormControl<BooleanOrNull>(null, [
          Validators.required,
        ]),
        prevent_close_aliquot: new FormControl<BooleanOrNull>(null, [
          Validators.required,
        ]),
      }
    );
    this.interestConfigForm = new FormGroup<IInterestPenaltyFormInput>({
      rate: new FormControl<NumberOrNull>(null, {
        validators: [
          Validators.required,
          Validators.min(0),
          Validators.max(100),
        ],
      }),
      rate_type: new FormControl<StringOrNull>(null, {
        validators: [Validators.required],
      }),
      interest_type: new FormControl<StringOrNull>(null, {
        validators: [Validators.required],
      }),
      penalty_amount: new FormControl<NumberOrNull>(null, {
        validators: [Validators.required, Validators.min(0)],
      }),
      penalty_currency_type: new FormControl<StringOrNull>(null, {
        validators: [Validators.required],
      }),
      penalty_min_debt: new FormControl<NumberOrNull>(null, {
        validators: [Validators.required, Validators.min(0)],
      }),
      penalty_type: new FormControl<StringOrNull>(null, {
        validators: [Validators.required],
      }),
      penalty_fund_id: new FormControl<NumberOrNull>(null, {
        validators: [Validators.required],
      }),
    });
    this.remunetarionConfigForm = new FormGroup<IRemunerationConfigFormInput>({
      mutual_insurance: new FormControl<StringOrNull>(null, {
        validators: [Validators.required],
      }),
      mutual_branch: new FormControl<StringOrNull>(null, {
        validators: [],
      }),
      mutual_rate: new FormControl<NumberOrNull>(null, {
        validators: [Validators.required],
      }),
      isl_rate: new FormControl<NumberOrNull>(null, {
        validators: [Validators.required],
      }),
      compensation_fund: new FormControl<StringOrNull>(null, {
        validators: [Validators.required],
      }),
      remuneration_expense_category_id: new FormControl<NumberOrNull>(null, {
        validators: [],
      }),
      create_expense_on_settlement: new FormControl<BooleanOrNull>(null, {
        validators: [Validators.required],
      }),
      include_expenses_in_reconciliation: new FormControl<BooleanOrNull>(null, {
        validators: [Validators.required],
      }),
      show_canceled_settlements: new FormControl<BooleanOrNull>(null, {
        validators: [Validators.required],
      }),
      create_expense_on_advance: new FormControl<BooleanOrNull>(null, {
        validators: [Validators.required],
      }),
      union_organization_rut: new FormControl<StringOrNull>(null, {
        validators: [Validators.required, Validators.pattern(/^\d{7,8}-[\d|kK]$/)],
      }),
      bulk_settlements_enabled: new FormControl<BooleanOrNull>(null, {
        validators: [Validators.required],
      }),
    });
  }

  async condoLoadRequest(): Promise<Observable<Array<ICondo>>> {
    return this.apollo
      .query<IQueryGetCondos>({
        query: QUERY_GET_ALL_CONDOS,
        fetchPolicy: 'network-only',
      })
      .pipe(
        map((response) => {
          if (response.data && response.data.GetAllCondos) {
            return response.data.GetAllCondos;
          } else {
            throw new Error('No se pudo obtener listado de condominios');
          }
        })
      );
  }
  async condoToggleCommonAreaReservationTimeRange(
    id: number
  ): Promise<Observable<Array<ICondo>>> {
    return this.apollo
      .mutate<IMutationToggleCommonAreaReservationTimeRange, { id: number }>({
        mutation: mutation_toggle_common_area_reservation_time_range_support,
        variables: { id },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map((response) => {
          if (
            response.data &&
            response.data.ToggleCommonAreaReservationTimeRangeSupport
          ) {
            return response.data.ToggleCommonAreaReservationTimeRangeSupport;
          } else {
            throw new Error('No se pudo actualizar condominio');
          }
        })
      );
  }
  async condoToggleAlphanumericKeyboardUsage(
    id: number
  ): Promise<Observable<Array<ICondo>>> {
    return this.apollo
      .mutate<IMutationToggleAlphanumeriKeyboardUsageResponse, { id: number }>({
        mutation: mutation_toggle_alphanumeri_keyboard_usage,
        variables: { id },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map((response) => {
          if (response.data && response.data.toggleAlphanumeriKeyboardUsage) {
            return response.data.toggleAlphanumeriKeyboardUsage;
          } else {
            throw new Error('No se pudo actualizar condominio');
          }
        })
      );
  }
  async condoToggleCommonExpensesVisibility(
    id: number
  ): Promise<Observable<Array<ICondo>>> {
    return this.apollo
      .mutate<
        IMutationToggleCommonExpensesVisibilityResponseData,
        { id: number }
      >({
        mutation: TOGGLE_COMMON_EXPENSES_VISIBILITY,
        variables: { id },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map((response) => {
          if (response.data && response.data.toggleCommonExpensesVisibility) {
            return response.data.toggleCommonExpensesVisibility;
          } else {
            throw new Error('No se pudo actualizar condominio');
          }
        })
      );
  }

  async condoRegister(inputs: ICondoInput): Promise<Observable<Array<ICondo>>> {
    console.log('request para registro');

    return this.apollo
      .mutate<IMutationCondosRegister, { FormCreateCondo: ICondoInput }>({
        mutation: mutation_register_condos,
        variables: { FormCreateCondo: inputs },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map((response) => {
          if (response.data && response.data.RegisterCondo) {
            return response.data.RegisterCondo;
          } else {
            throw new Error('No se pudo actualizar condominio');
          }
        })
      );
  }

  async condoUpdate(
    inputs: ICondoUpdateInput
  ): Promise<Observable<Array<ICondo>>> {
    console.log('request para registro');

    return this.apollo
      .mutate<IMutationUpdateCondos, { FormUpdateCondo: ICondoUpdateInput }>({
        mutation: mutation_update_condos,
        variables: { FormUpdateCondo: inputs },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map((response) => {
          console.log('response', response);

          if (response.data && response.data.UpdateCondo) {
            return response.data.UpdateCondo;
          } else {
            throw new Error('No se pudo actualizar condominio');
          }
        })
      );
  }

  async getCondoByID(id: number) {
    return this.apollo
      .query<IQueryGetCondoByID>({
        query: query_get_condo_by_id,
        variables: { id },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map((response) => {
          if (response.data && response.data.GetCondo) {
            return response.data.GetCondo;
          } else {
            throw new Error('No se pudo obtener listado de condominios');
          }
        })
      );
  }

  setGlobalCondo(id: number, name: string) {
    sessionStorage.setItem(StorageKeys.session_condo, `${id}`);
    sessionStorage.setItem(StorageKeys.session_condo_name, name);
  }

  removeGlobalCondo() {
    sessionStorage.removeItem(StorageKeys.session_condo);
    sessionStorage.removeItem(StorageKeys.session_condo_name);
  }

  getGlobalCondo() {
    return sessionStorage.getItem(StorageKeys.session_condo);
  }

  getGlobalCondoName() {
    return sessionStorage.getItem(StorageKeys.session_condo_name);
  }

  async getCondoSettings(): Promise<Observable<ICondoSettings>> {
    return this.apollo
      .query<IGetCondoSettings>({
        query: query_get_condo_settings,
        fetchPolicy: 'network-only',
      })
      .pipe(
        map((response) => {
          if (response.data) {
            return response.data.GetCondoSettings ?? null;
          } else {
            throw new Error(
              'No se pudo obtener la configuración de condominio'
            );
          }
        })
      );
  }

  async updateCondoSettings(
    input: Partial<ICondoSettingsInput>,
    type: ESettingItem
  ): Promise<ICondoSettings | undefined> {
    try {
      return await firstValueFrom(
        this.apollo
          .mutate<IMutationUpdateCondoSettings>({
            mutation: mutation_update_condo_settings,
            variables: { input },
            fetchPolicy: 'network-only',
          })
          .pipe(
            map((response) => {
              if (response.data && response.data.UpdateCondoSettings) {
                return response.data.UpdateCondoSettings;
              } else {
                throw new Error(
                  'No se pudo actualizar configuración de condominio'
                );
              }
            })
          )
      );
    } catch (error) {
      console.log(type);
      this.errorService.handleGraphQLErrorsForForm(
        (error as any).graphQLErrors,
        type === ESettingItem.Configuración
          ? this.commonExpenseConfigForm
          : this.interestConfigForm
      );
      return;
    }
  }

  getErrorMessage(controlName: keyof ICondoFormInput): string | null {
    const inputControl = this.condoForm.get(controlName);
    if (inputControl && inputControl.errors) {
      if (inputControl.errors['serverError']) {
        return inputControl.errors['serverError'];
      }
      for (const errorKey in inputControl.errors) {
        if (errorKey in this.errorMessages[controlName]) {
          return this.errorMessages[controlName][errorKey];
        }
      }
    }
    return null;
  }

  getCommonExpenseErrorMessage(
    controlName: keyof ICommonExpenseConfigFormInput
  ): string | null {
    const inputControl = this.commonExpenseConfigForm.get(controlName);
    if (inputControl && inputControl.errors) {
      if (inputControl.errors['serverError']) {
        return inputControl.errors['serverError'];
      }
      for (const errorKey in inputControl.errors) {
        if (errorKey in this.commonExpenseErrorMessages[controlName]) {
          return this.commonExpenseErrorMessages[controlName][errorKey];
        }
      }
    }
    return null;
  }

  getInterestErrorMessage(
    controlName: keyof IInterestPenaltyFormInput
  ): string | null {
    const inputControl = this.interestConfigForm.get(controlName);
    if (inputControl && inputControl.errors) {
      if (inputControl.errors['serverError']) {
        return inputControl.errors['serverError'];
      }
      for (const errorKey in inputControl.errors) {
        if (errorKey in this.interestErrorMessages[controlName]) {
          return this.interestErrorMessages[controlName][errorKey];
        }
      }
    }
    return null;
  }

  getRemunerationErrorMessage(
    controlName: keyof IRemunerationConfigFormInput
  ): string | null {
    const inputControl = this.remunetarionConfigForm.get(controlName);
    if (inputControl && inputControl.errors) {
      if (inputControl.errors['serverError']) {
        return inputControl.errors['serverError'];
      }
      for (const errorKey in inputControl.errors) {
        if (errorKey in this.remunerationErrorMessages[controlName]) {
          return this.remunerationErrorMessages[controlName][errorKey];
        }
      }
    }
    return null;
  }
}
