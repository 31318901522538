<!-- justify at the start -->
<c-container class="m-2 d-flex justify-content-start gap-2">
    <button cButton color="primary" class="p-1 mb-1" block (click)="navToCondoSelect()">
        <h5 cCardTitle class="text-light">{{condoName}}</h5>
    </button>
    @if(currentPeriod) {
    <div>
        <c-card>
            <c-card-body>
                <h6 cCardTitle class="m-0 fs-6">
                    Periodo en curso:
                    <span class="text-primary">
                        <strong>{{currentPeriod}}</strong>
                    </span>
                </h6>
            </c-card-body>
        </c-card>
    </div>
    }
    @if(collectingPeriod) {
    <div>
        <c-card>
            <c-card-body>
                <h6 cCardTitle class="m-0 fs-6">
                    Periodo en recaudación:
                    <span class="text-warning">
                        <strong>
                            {{collectingPeriod}}
                        </strong>
                    </span>
                </h6>
            </c-card-body>
        </c-card>
    </div>
    }
</c-container>