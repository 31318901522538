import { ICondoSettings } from '@core-ports/outputs/CondoSettings';
import { ICondo } from '@core-ports/outputs/condo';
import { gql } from 'apollo-angular';

export type IMutationCondosRegister = {
  RegisterCondo: null | undefined | Array<ICondo>;
};

export type IMutationUpdateCondos = {
  UpdateCondo: null | undefined | Array<ICondo>;
};

export type RequestDeleteCondos = {
  DeleteCondo: null | undefined | Array<ICondo>;
};

export type IQueryGetCondos = {
  GetAllCondos: ICondo[];
};

export type IMutationToggleCommonAreaReservationTimeRange = {
  ToggleCommonAreaReservationTimeRangeSupport: ICondo[];
};

export type IMutationToggleAlphanumeriKeyboardUsageResponse = {
  toggleAlphanumeriKeyboardUsage: ICondo[];
};

export type IMutationToggleCommonExpensesVisibilityResponseData = {
  toggleCommonExpensesVisibility: ICondo[];
};

export type IQueryGetCondoByID = {
  GetCondo: ICondo;
};

export type IGetCondoSettings = {
    GetCondoSettings: ICondoSettings;
};

export type IMutationUpdateCondoSettings = {
    UpdateCondoSettings: ICondoSettings;
};

// TODO integrar activación de PLAT

const graphQLSchema = `
    id
    name
    pairing_code
    maximum_parking_time
    inactivity_time
    address
    primary_phone_number
    secondary_phone_number
    devices_number
    supports_plat
    supports_common_area_reservation_time_ranges
    should_use_alphanumeric_keyboard_for_property_search
    should_show_common_expenses_to_residents
    active
`;

const grapQLCondoSettingsSchema = `
id
type
consumption_calculation
show_zero_cols
expiration_day
expiration_day
gen_pdf
show_totals
virtual_assistant
prevent_close_aliquot
rate
interest_type
rate_type
penalty_amount
penalty_type
penalty_min_debt
penalty_currency_type
penalty_fund_id
mutual_insurance
mutual_branch
mutual_rate
isl_rate
compensation_fund
remuneration_expense_category_id
create_expense_on_settlement
include_expenses_in_reconciliation
show_canceled_settlements
create_expense_on_advance
union_organization_rut
bulk_settlements_enabled
`;

export const mutation_register_condos = gql`
    mutation RegisterCondo($FormCreateCondo:FormCreateCondo!){
        RegisterCondo(FormCreateCondo:$FormCreateCondo){
            ${graphQLSchema}
        }
    }
`;

export const mutation_update_condos = gql`
    mutation UpdateCondo($FormUpdateCondo:FormUpdateCondo!){
        UpdateCondo(FormUpdateCondo:$FormUpdateCondo){
            ${graphQLSchema}
        }
    }
`;

export const mutation_delete_condos = gql`
    mutation DeleteCondo($condo_id: Int!){
        DeleteCondo(condo_id:$condo_id){
            ${graphQLSchema}
        }
    }
`;

export const query_get_condo_by_id = gql`
    query Condo ($id: Int!){
        GetCondo(id: $id){
            ${graphQLSchema}
        }
    }
`;
export const QUERY_GET_ALL_CONDOS = gql`
    query GetAllCondos{
        GetAllCondos{
            ${graphQLSchema}
        }
    }
`;

export const mutation_toggle_common_area_reservation_time_range_support = gql`
    mutation ToggleCommonAreaReservationTimeRangeSupport($id: Int!) {
        ToggleCommonAreaReservationTimeRangeSupport(id: $id) {
            ${graphQLSchema}
        }
    }
`;
export const mutation_toggle_alphanumeri_keyboard_usage = gql`
    mutation ToggleAlphanumeriKeyboardUsage($id: Int!) {
        toggleAlphanumeriKeyboardUsage(id: $id) {
            ${graphQLSchema}
        }
    }
`;

export const TOGGLE_COMMON_EXPENSES_VISIBILITY = gql`
    mutation ToggleCommonExpensesVisibility($id: Int!) {
        toggleCommonExpensesVisibility(id: $id) {
            ${graphQLSchema}
        }
    }
`;


export const query_get_condo_settings = gql`
query GetCondoSettings {
	GetCondoSettings {
        ${grapQLCondoSettingsSchema}
    }
}
`;


export const mutation_update_condo_settings = gql`
mutation UpdateCondoSettings(
	$input: CondoSettingsInput!
) {
	UpdateCondoSettings(
		input: $input
	) {
        ${grapQLCondoSettingsSchema}
    }
}
`;
