<c-toaster placement="top-center" class="p-3" position="fixed">
    <c-toast
      [visible]="!!message"
      (visibleChange)="onVisibleChange($event)"
      color="warning"
    >
      <c-toast-header>
        ¡Atención!
      </c-toast-header>
      <c-toast-body>
        <p>{{message}}</p>
      </c-toast-body>
    </c-toast>
  </c-toaster>