import { inject } from '@angular/core';
import {
  CanActivateFn,
  Router,
  type GuardResult,
} from '@angular/router';
import { BillingPeriodManager } from '@infra-adapters/services/BillingPeriod.service';


export const PeriodGuard: CanActivateFn = async (
    route,
    state
  ): Promise<GuardResult> => {
    const billingPeriodService = inject(BillingPeriodManager);
    try {
        await billingPeriodService.getAndUpdateActivePeriods();
        return true;
    } catch (error) {
        console.log(error);
        const router = inject(Router);
        return router.navigate(['/page500'], { queryParams: { message: 'No se encontraron periodos activos' } });
    }
  };
  