<!--<c-header class="mb-4 d-print-none" position="sticky">-->
<ng-container>
  <c-container [fluid]="true" class="px-4">
    <!-- <button
      [cSidebarToggle]="sidebarId"
      cHeaderToggler
      class="btn d-lg-none pe-3"
      toggle="visible"
      style="margin-inline-start: -14px"
    >
      <svg cIcon name="cilMenu" size="lg"></svg>
    </button> -->
    <c-row class="align-items-center w-100 justify-content-between">
      <c-col xs="auto">
        <active-billing-period></active-billing-period>
      </c-col>
      <!-- <c-col xs="3">
        <c-header-nav class="ms-auto ms-md-0">
          <div class="nav-item py-1">
            <div class="vr h-100 mx-2 text-body text-opacity-75"></div>
          </div>
          <div class="nav-item py-1">
            <div class="vr h-100 mx-2 text-body text-opacity-75"></div>
          </div>
        </c-header-nav>
      </c-col> -->
      <c-col xs="auto">
        <c-header-nav class="ms-0">
          <ng-container *ngTemplateOutlet="userDropdown" />
        </c-header-nav>
      </c-col>
    </c-row>
    <!-- <form cForm class="d-none d-sm-flex">
      <c-input-group>
        <span
          cInputGroupText
          id="search-addon"
          class="bg-body-secondary border-0 px-1"
        >
          <svg
            cIcon
            name="cilSearch"
            size="lg"
            class="my-1 mx-2 text-body-secondary"
          ></svg>
        </span>
        <input
          cFormControl
          placeholder="Search..."
          aria-label="Search"
          aria-describedby="search-addon"
          class="bg-body-secondary border-0"
        />
      </c-input-group>
    </form> -->
    <!-- <c-header-nav class="d-none d-md-flex ms-auto">
       <ng-container *ngTemplateOutlet="notifyDropdown" />
      <ng-container *ngTemplateOutlet="taskDropdown" />
      <ng-container *ngTemplateOutlet="messagesDropdown" />
    </c-header-nav> -->

    <!--aside toggler-->
    <!-- <button
      cHeaderToggler
      cSidebarToggle="aside1"
      style="margin-inline-end: -12px"
      toggle="visible"
    >
      <svg cIcon name="cilApplicationsSettings" size="lg"></svg>
    </button> -->
  </c-container>
</ng-container>
<!--</c-header>-->

<ng-template #userDropdown>
  <c-dropdown [popperOptions]="{ placement: 'bottom-start' }" variant="nav-item">
    <button [caret]="false" cDropdownToggle class="py-0">
      <c-avatar size="md" src="./assets/plat-logo-2.png" status="success" textColor="primary" />
    </button>
    <ul cDropdownMenu class="pt-0 w-auto">
      <li>
        <h6 cDropdownHeader class="bg-body-secondary text-body-secondary fw-semibold py-2 rounded-top">
          Account
        </h6>
      </li>
      <li>
        <a cDropdownItem routerLink="">
          <svg cIcon class="me-1" name="cilBell"></svg>
          Updates
          <c-badge class="ms-2 float-end" color="info-gradient"> 42</c-badge>
        </a>
      </li>
      <li>
        <a cDropdownItem routerLink="/apps/email/inbox">
          <svg cIcon class="me-1" name="cilEnvelopeOpen"></svg>
          Messages
          <c-badge class="ms-2 float-end" color="success-gradient">
            42
          </c-badge>
        </a>
      </li>
      <li>
        <a cDropdownItem routerLink="">
          <svg cIcon class="me-1" name="cilTask"></svg>
          Tasks
          <c-badge class="ms-2 float-end" color="danger-gradient"> 42</c-badge>
        </a>
      </li>
      <li>
        <a cDropdownItem routerLink="">
          <svg cIcon class="me-1" name="cilCommentSquare"></svg>
          Comments
          <c-badge class="ms-auto" color="warning-gradient"> 42</c-badge>
        </a>
      </li>
      <li>
        <h6 cDropdownHeader class="bg-body-secondary text-body-secondary fw-semibold py-2">
          Settings
        </h6>
      </li>
      <li></li>
      <li>
        <a cDropdownItem routerLink="">
          <svg cIcon class="me-1" name="cilUser"></svg>
          Profile
        </a>
      </li>
      <li>
        <a cDropdownItem routerLink="">
          <svg cIcon class="me-1" name="cilSettings"></svg>
          Settings
        </a>
      </li>
      <li>
        <a cDropdownItem routerLink="">
          <svg cIcon class="me-1" name="cilCreditCard"></svg>
          Payments
          <c-badge class="ms-2 float-end" color="secondary-gradient">
            42
          </c-badge>
        </a>
      </li>
      <li>
        <a cDropdownItem routerLink="">
          <svg cIcon class="me-1" name="cilFile"></svg>
          Projects
          <c-badge class="ms-2 float-end" color="primary-gradient">
            42
          </c-badge>
        </a>
      </li>
      <li>
        <hr cDropdownDivider />
      </li>
      <li>
        <a cDropdownItem routerLink="">
          <svg cIcon class="me-1" name="cilLockLocked"></svg>
          Lock Account
        </a>
      </li>
      <li>
        <a cDropdownItem routerLink="" (click)="logout()">
          <svg cIcon class="me-1" name="cilAccountLogout"></svg>
          Logout
        </a>
      </li>
    </ul>
  </c-dropdown>
</ng-template>

<ng-template #messagesDropdown>
  <c-dropdown alignment="end" variant="nav-item">
    <button [caret]="false" cDropdownToggle>
      <span class="d-inline-block my-1 mx-2 position-relative">
        <svg cIcon name="cilEnvelopeOpen" size="lg"></svg>
        @if (newMessages?.length) {
        <c-badge class="p-1" color="danger" shape="rounded-circle" position="top-end">
          <span class="visually-hidden">{{ newMessages?.length ?? 0 }} new alerts</span>
        </c-badge>
        }
      </span>
    </button>
    <div cDropdownMenu class="p-0" [ngStyle]="{ minWidth: '24rem' }">
      <h6 cDropdownHeader class="bg-body-secondary text-body-secondary fw-semibold rounded-top mb-2">
        <span>You have {{ newMessages?.length ?? 0 }} messages</span>
      </h6>
      @for (message of newMessages; track message.id) {
      <a cDropdownItem routerLink="{{ message.link }}">
        <div class="d-flex">
          <c-avatar size="" src="./assets/images/avatars/{{ message.avatar }}" status="{{ message.status }}"
            class="flex-shrink-0 my-3 me-3" />
          <div class="message text-wrap">
            <div class="d-flex justify-content-between small text-body-secondary mt-1">
              <div>{{ message.from }}</div>
              <div>{{ message.time }}</div>
            </div>
            <div class="fw-semibold">
              @if (message.id < 2) { <span class="text-danger me-1">!</span>
                }
                <span>{{ message.title }}</span>
            </div>
            <div class="small text-body-secondary">
              {{ message.message }}
            </div>
          </div>
        </div>
      </a>
      }
      <div class="p-2">
        <button cButton color="primary" variant="outline" class="w-100" routerLink="apps/email/inbox">
          View all messages
        </button>
      </div>
    </div>
  </c-dropdown>
</ng-template>

<ng-template #notifyDropdown>
  <c-dropdown alignment="end" variant="nav-item">
    <button [caret]="false" cDropdownToggle>
      <span class="d-inline-block my-1 mx-2 position-relative">
        <svg cIcon name="cilBell" size="lg"></svg>
        @if (newNotifications?.length) {
        <c-badge class="p-1" color="danger" position="top-end" shape="rounded-circle">
          <span class="visually-hidden">{{ newNotifications?.length ?? 0 }} new alerts</span>
        </c-badge>
        }
      </span>
    </button>
    <div cDropdownMenu class="p-0">
      <h6 cDropdownHeader class="bg-body-secondary text-body-secondary fw-semibold rounded-top">
        <span>You have {{ newNotifications?.length ?? 0 }} notifications</span>
      </h6>
      @for (notification of newNotifications; track notification.id) {
      <a cDropdownItem routerLink="">
        <svg cIcon class="me-2 text-{{ notification.color }}" name="{{ notification.icon }}"></svg>
        {{ notification.title }}
      </a>
      }
      <h6 cDropdownHeader class="bg-body-secondary text-body-secondary fw-semibold">
        <span>Server</span>
      </h6>
      @for (status of newStatus; track status.id; let last = $last) {
      <a cDropdownItem class="d-block {{ last ? 'py-1' : 'py-2' }}" routerLink="">
        <div class="text-uppercase small fw-semibold mb-1">
          {{ status.title }}
        </div>
        <c-progress thin [value]="status.value" color="{{ status.color }}-gradient" />
        <span class="text-body-secondary small">{{ status.details }}</span>
      </a>
      }
    </div>
  </c-dropdown>
</ng-template>

<ng-template #taskDropdown>
  <c-dropdown alignment="end" variant="nav-item">
    <button [caret]="false" cDropdownToggle href>
      <span class="d-inline-block my-1 mx-2 position-relative">
        <svg cIcon name="cilListRich" size="lg"></svg>
        @if (newTasks?.length) {
        <c-badge class="p-1" color="danger" position="top-end" shape="rounded-circle">
          <span class="visually-hidden">{{ newTasks.length ?? 0 }} new alerts</span>
        </c-badge>
        }
      </span>
    </button>
    <div cDropdownMenu class="pt-0">
      <h6 cDropdownHeader class="bg-body-secondary text-body-secondary fw-semibold rounded-top">
        <span>You have {{ newTasks.length ?? 0 }} pending tasks</span>
      </h6>
      @for (task of newTasks; track task.id) {
      <a cDropdownItem class="d-block py-2" routerLink="">
        <div class="d-flex justify-content-between mb-1">
          <span class="small">{{ task.title }} </span>
          <span class="fw-semibold">{{ task.value }}%</span>
        </div>
        <c-progress thin [value]="task.value" color="{{ task.color }}-gradient" />
      </a>
      }
      <div class="px-2 pt-2">
        <button cButton color="primary" variant="outline" class="w-100" routerLink="">
          View all tasks
        </button>
      </div>
    </div>
  </c-dropdown>
</ng-template>

<ng-template #languageDropdown>
  <c-dropdown alignment="end" variant="nav-item">
    <button [caret]="false" cDropdownToggle disabled>
      <svg cIcon name="cilLanguage" size="lg"></svg>
    </button>
    <!-- <div cDropdownMenu></div>-->
  </c-dropdown>
</ng-template>

<ng-template #themeDropdown>
  <c-dropdown alignment="end" variant="nav-item">
    <button [caret]="false" cDropdownToggle>
      @switch (colorMode()) { @case ("dark") {
      <svg cIcon name="cilMoon" size="lg"></svg>
      } @case ("auto") {
      <svg cIcon name="cilContrast" size="lg"></svg>
      } @default {
      <svg cIcon name="cilSun" size="lg"></svg>
      } }
    </button>
    <div cDropdownMenu>
      <button (click)="colorMode.set('light')" [active]="colorMode() === 'light'" [routerLink]="[]" cDropdownItem
        class="d-flex align-items-center">
        <svg cIcon class="me-2" name="cilSun" size="lg"></svg>
        Light
      </button>
      <button (click)="colorMode.set('dark')" [active]="colorMode() === 'dark'" [routerLink]="[]" cDropdownItem
        class="d-flex align-items-center">
        <svg cIcon class="me-2" name="cilMoon" size="lg"></svg>
        Dark
      </button>
      <button (click)="colorMode.set('auto')" [active]="colorMode() === 'auto'" [routerLink]="[]" cDropdownItem
        class="d-flex align-items-center">
        <svg cIcon class="me-2" name="cilContrast" size="lg"></svg>
        Auto
      </button>
    </div>
  </c-dropdown>
</ng-template>