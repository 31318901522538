import { inject } from '@angular/core';
import {
  CanActivateFn,
  Router,
  type GuardResult,
  type MaybeAsync,
} from '@angular/router';
import { AuthManager } from '@infra-adapters/services/Auth.service';

export const RoleGuard: CanActivateFn = (
  route,
  state
): MaybeAsync<GuardResult> => {
  const authService = inject(AuthManager);
  const router = inject(Router);

  const allowedRoles = route.data['roles'] as string[];
  console.log(allowedRoles);

  const userRole = authService.UserSessionData()!.role;

  if (allowedRoles.includes(userRole)) {
    return true;
  }

  const attemptedUrl = state.url;

  console.log("XD");
  console.log(userRole);
  console.log("AttemptedUrl: " + attemptedUrl);
  if (attemptedUrl.startsWith('/proveedores')) {
    return router.navigate(['/']);
  } else {
    return router.navigate(['/proveedores']);
  }
};
