import { IBillingPeriod } from '@core-ports/outputs/BillingPeriod';
import { gql } from 'apollo-angular';

export type IQueryGetBillingPeriod = {
	GetBillingPeriod: IBillingPeriod;
};

export type IQueryGetBillingPeriodByDate = {
  GetBillingPeriodByDate: IBillingPeriod | null;
};

export const graphQLBillingPeriodSchemaSchema = `
id
start_date
expiration_date
facturation_date
state
`;

export const query_get_billing_period = gql`
  query GetBillingPeriod($filter: Object!) {
    GetBillingPeriod(filter: $filter) {
      ${graphQLBillingPeriodSchemaSchema}
    }
  }
`;


export const query_get_billing_period_by_date = gql`
query GetBillingPeriod(
	$period_date: Date!
) {
	GetBillingPeriodByDate(
		period_date: $period_date
	) {
    ${graphQLBillingPeriodSchemaSchema}      
	}
}
`

export const mutation_update_billing_period = gql`
  mutation UpdateBillingPeriod($id: Int!, $input: BillingPeriodInput!) {
    UpdateBillingPeriod(id: $id, input: $input) {
      ${graphQLBillingPeriodSchemaSchema}
    }
  }
`;
