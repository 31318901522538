import { Component, OnInit } from '@angular/core';
import { BillingPeriodManager } from 'src/infrastructure/adapters/services/BillingPeriod.service';
import {
  AlertComponent,
  BorderDirective,
  ButtonDirective,
  ButtonGroupComponent,
  CardBodyComponent,
  CardComponent,
  CardGroupComponent,
  CardHeaderComponent,
  CardLinkDirective,
  ColComponent,
  ContainerComponent,
  FormCheckComponent,
  FormCheckInputDirective,
  FormCheckLabelDirective,
  FormControlDirective,
  FormDirective,
  FormSelectDirective,
  GutterDirective,
  InputGroupComponent,
  InputGroupTextDirective,
  RowComponent,
  TextColorDirective,
} from '@coreui/angular-pro';
import { IconDirective, IconModule } from '@coreui/icons-angular';
import { CommonModule, NgStyle } from '@angular/common';
import { firstValueFrom } from 'rxjs';
import { CondoManager } from '@infra-adapters/services/Condo.service';
import { Router } from '@angular/router';

@Component({
  selector: 'active-billing-period',
  standalone: true,
  imports: [
    ContainerComponent,
    RowComponent,
    ColComponent,

    NgStyle,
    CommonModule,

    ButtonDirective,
    ButtonGroupComponent,

    IconDirective,

    InputGroupComponent,
    InputGroupTextDirective,

    FormSelectDirective,

    FormDirective,
    FormControlDirective,

    GutterDirective,
    IconModule,

    FormCheckComponent,
    FormCheckInputDirective,
    FormCheckLabelDirective,

    CardBodyComponent,
    CardComponent,
    CardGroupComponent,
    CardHeaderComponent,
    CardLinkDirective,
    AlertComponent,
    BorderDirective,
    TextColorDirective,

    GutterDirective
  ],
  templateUrl: './active-billing-period.component.html',
  styleUrl: './active-billing-period.component.scss',
})
export class ActiveBillingPeriodComponent implements OnInit {
  currentPeriod: string = '';
  collectingPeriod: string = '';
  condoName = '';

  constructor(
    private condoService: CondoManager,
    private billingPeriodManager: BillingPeriodManager,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    this.condoName = this.condoService.getGlobalCondoName() || '';
    this.billingPeriodManager.activeBillingPeriods.subscribe((periods) => {
      this.currentPeriod = periods.current_period
        ? this.billingPeriodManager.formatPeriodToMonthYear(
            new Date(periods.current_period.start_date)
          )
        : '';
      this.collectingPeriod = periods.collecting_period
        ? this.billingPeriodManager.formatPeriodToMonthYear(
            new Date(periods.collecting_period.start_date)
          )
        : '';
      console.log('Current period: ', this.currentPeriod);
      console.log('Collecting period: ', this.collectingPeriod);
    });
  }

  navToCondoSelect() {
    this.router.navigate(['/condo/select']);
  }
}
