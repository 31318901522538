import { Component, OnInit } from '@angular/core';
import { ToastBodyComponent, ToastCloseDirective, ToastComponent, ToastHeaderComponent, ToasterComponent } from '@coreui/angular-pro';
import { ErrorService } from 'src/infrastructure/adapters/services/Error.service';

@Component({
  selector: 'error-toast',
  standalone: true,
  imports: [
    ToastComponent,
    ToasterComponent,
    ToastCloseDirective,
    ToastBodyComponent,
    ToastHeaderComponent,
  ],
  templateUrl: './error-toast.component.html',
  styleUrl: './error-toast.component.scss'
})
export class ErrorToastComponent implements OnInit{
  message: string | null = null;

  constructor(
    private errorService: ErrorService
  ) {}

  ngOnInit(): void {
    this.errorService.errorMessage.subscribe((message) => {
      this.message = message;
    });
  }

  onVisibleChange($event: boolean) {
    if (!$event) {
      this.errorService.errorMessage.next(null);
    }
  }



}
