import { inject } from '@angular/core';
import {
  CanActivateFn,
  Router,
  type MaybeAsync,
  type GuardResult,
} from '@angular/router';
import { CondoManager } from '@infra-adapters/services/Condo.service';

export const SelectedCondoGuard: CanActivateFn = (
  route,
  state
): MaybeAsync<GuardResult> => {
  const condoService = inject(CondoManager);
  const router = inject(Router);
  const condoId = condoService.getGlobalCondo();
  if (condoId) {
    return true;
  } else {
    return router.createUrlTree(['/condo/select']);
  }
};
