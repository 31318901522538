import type { ICondo } from '@core-ports/outputs/condo';
import type { ICondoInput } from '@core-ports/inputs/Condo';
import type { Observable } from 'rxjs';
import type { FormControl } from '@angular/forms';
import type { BooleanOrNull, NumberOrNull, StringOrNull } from '@core-interfaces/global';
import { ICondoSettingsInput } from '@core-ports/inputs/CondoSettings';
import { ICondoSettings } from '@core-ports/outputs/CondoSettings';

type CondoInputFieldOmit =
  | 'name'
  | 'pairing_code'
  | 'maximum_parking_time'
  | 'inactivity_time'
  | 'address'
  | 'primary_phone_number'
  | 'secondary_phone_number'
  | 'devices_number';
export interface ICondoFormInput
  extends Omit<ICondoInput, CondoInputFieldOmit> {
  name: FormControl<StringOrNull>;
  pairing_code: FormControl<NumberOrNull>;
  maximum_parking_time: FormControl<NumberOrNull>;
  inactivity_time: FormControl<NumberOrNull>;
  address: FormControl<StringOrNull>;
  primary_phone_number: FormControl<StringOrNull>;
  secondary_phone_number: FormControl<StringOrNull>;
  devices_number: FormControl<NumberOrNull>;
}

type CondoSettingsFieldOmit =
| 'type'
| 'consumption_calculation'
| 'show_zero_cols'
| 'expiration_day'
| 'gen_pdf'
| 'show_totals'
| 'virtual_assistant'
| 'prevent_close_aliquot'
  | 'rate'
  | 'rate_type'
  | 'interest_type'
  | 'penalty_type'
  | 'penalty_amount'
  | 'penalty_currency_type'
  | 'penalty_min_debt'
  | 'penalty_fund_id'
  | 'mutual_insurance'
  | 'mutual_branch'
  | 'mutual_rate'
  | 'isl_rate'
  | 'compensation_fund'
  | 'remuneration_expense_category_id'
  | 'create_expense_on_settlement'
  | 'include_expenses_in_reconciliation'
  | 'show_canceled_settlements'
  | 'create_expense_on_advance'
  | 'union_organization_rut'
  | 'bulk_settlements_enabled'
  | 'remuneration_signature_reference';


  // mutual_insurance?: string;
	// mutual_branch?: string;
	// mutual_rate?: number;
	// isl_rate?: number;
	// compensation_fund?: string;
	// remuneration_expense_category_id?: number;
	// create_expense_on_settlement?: boolean;
	// include_expenses_in_reconciliation?: boolean;
	// show_canceled_settlements?: boolean;
	// create_expense_on_advance?: boolean;
	// union_organization_rut?: string;
	// bulk_settlements_enabled?: boolean;
	// remuneration_signature_reference?: string;
  
export interface IInterestPenaltyFormInput
  extends Omit<ICondoSettingsInput, CondoSettingsFieldOmit> {
    rate: FormControl<NumberOrNull>;
    rate_type: FormControl<StringOrNull>;
    interest_type: FormControl<StringOrNull>;
    penalty_type: FormControl<StringOrNull>;
    penalty_amount: FormControl<NumberOrNull>;
    penalty_currency_type: FormControl<StringOrNull>;
    penalty_min_debt: FormControl<NumberOrNull>;
    penalty_fund_id: FormControl<NumberOrNull>;
}
export interface ICommonExpenseConfigFormInput
  extends Omit<ICondoSettingsInput, CondoSettingsFieldOmit> {
  type: FormControl<StringOrNull>;
  consumption_calculation: FormControl<StringOrNull>;
  show_zero_cols: FormControl<BooleanOrNull>;
  expiration_day: FormControl<StringOrNull>;
  gen_pdf: FormControl<BooleanOrNull>;
  show_totals: FormControl<BooleanOrNull>;
  virtual_assistant: FormControl<BooleanOrNull>;
  prevent_close_aliquot: FormControl<BooleanOrNull>;
}


export interface IRemunerationConfigFormInput
  extends Omit<ICondoSettingsInput, CondoSettingsFieldOmit> {
  mutual_insurance: FormControl<StringOrNull>;
  mutual_branch: FormControl<StringOrNull>;
  mutual_rate: FormControl<NumberOrNull>;
  isl_rate: FormControl<NumberOrNull>;
  compensation_fund: FormControl<StringOrNull>;
  remuneration_expense_category_id: FormControl<NumberOrNull>;
  create_expense_on_settlement: FormControl<BooleanOrNull>;
  include_expenses_in_reconciliation: FormControl<BooleanOrNull>;
  show_canceled_settlements: FormControl<BooleanOrNull>;
  create_expense_on_advance: FormControl<BooleanOrNull>;
  union_organization_rut: FormControl<StringOrNull>;
  bulk_settlements_enabled: FormControl<BooleanOrNull>;
}


export enum ESettingItem {
  General = 'General',
  Fondos = 'Fondos',
  Conciliación = 'Conciliación bancaria',
  Multas = 'Multas e intereses',
  Remuneraciones = 'Remuneraciones',
  Configuración = 'Configuración',
}


export interface ICondoService {
  /**
   * * solicitud para cargar listados de condominios por usuario en sesión
   * * es necesario token JWT para el uso de esta solicitud
   */
  condoLoadRequest(): Promise<Observable<Array<ICondo>>>;
  /**
   *  * Solicitud para habilitar o deshabilitar la opción de registrar un área común con rango de tiempo (PLAT Conserjería)
   * @param id identificador de condominio
   */
  condoToggleCommonAreaReservationTimeRange(
    id: number
  ): Promise<Observable<Array<ICondo>>>;

  /**
   * * Solicitud para activar búsqueda alfanumérica en PLAT Conserjería
   * @param id identificador de condominio
   */
  condoToggleAlphanumericKeyboardUsage(
    id: number
  ): Promise<Observable<Array<ICondo>>>;

  /**
   * * Solicitud para activar o desactivar componente de gasto común en aplicación PLAT Home
   * @param id identificador de condominio
   */
  condoToggleCommonExpensesVisibility(
    id: number
  ): Promise<Observable<Array<ICondo>>>;

  /**
   * * Solicita un condominio por ID
   * @param id identificador de condominio
   */
  getCondoByID(id: number): Promise<Observable<ICondo>>;

  getCondoSettings(): Promise<Observable<ICondoSettings>>;

  updateCondoSettings(
    input: Partial<ICondoSettingsInput>,
    type: ESettingItem
  ): Promise<ICondoSettings | undefined>;
}
